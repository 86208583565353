/* import contact.css */
@import url("/src/assets/css/contact.css");
@import url("/src/assets/css/folders.css");
@import url("/src/assets/css/newUser.css");

/* GENERAL */
.grey-bkg {
  background-color: #efefef;
}

.black-bkg {
  background-color: #191919;
}

.border-red {
  border: 1px solid red !important;
}

.red {
  color: red !important;
}

.page-align {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 200px);
}
.font {
  font-family: "Lucida Sans", sans-serif;
}

.margin-left {
  margin-left: 20px;
}

.margin-botton {
  margin-bottom: 40px;
}
.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 80%;
  height: calc(100vh - 300px);
  contain: content;
}

h1 {
  font-size: 30px;
  margin: 20px;
}

h2 {
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
}

h3 {
  font-size: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

p {
  font-size: 12px;
}

a {
  text-decoration: none;
  color: currentColor;
}
.text-center {
  text-align: center;
}

.forms-style {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.forms-style input {
  border: lightgrey 1px solid;
  height: 30px;
  width: 250px;
  padding: 15px;
}

.forms-style button {
  border: none;
  height: 30px;
  width: 250px;
  background-color: #b42f5a;
  color: white;
  cursor: pointer;
}

.forms-style label {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.forms-style textarea {
  width: 250px;
  height: 200px;
  border: lightgrey 1px solid;
  padding: 15px;
}

.error-message {
  color: #b42f5a;
  font-size: 12px;
}

/* HEADER */

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  background-color: #b42f5a;
  height: 100px;
  width: 100vw;
  padding-left: 30px;
  padding-right: 30px;
}

.header-container img {
  height: 49px;
  width: 188px;
}
.header-right {
  display: flex;
}
.header-text {
  color: white;
  margin-right: 15px;
}

/* FOOTER */
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #191919;
  height: 100px;
}

.footer-text {
  color: #efefef;
}
/* LOGIN PAGE */

.forms-container {
  margin-top: 80px;
  margin-bottom: 80px;
  display: flex;
  justify-content: space-around;
  gap: 100px;
}

.separator {
  background-color: grey;
  width: 1px;
  height: 300px;
}

@media (max-width: 900px) {
  .separator {
    visibility: hidden;
    height: 0px;
  }
  .forms-container {
    margin-top: 15px;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
}

/* HOME PAGE */

.flex-center {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 200px);
  height: fit-content;
  gap: 25px;
  margin-right: 25px;
}
.home-left {
  display: flex;
  padding: 25px;
  flex-direction: column;
  justify-content: top;
  align-items: left;
  width: 20%;
  min-height: calc(100vh - 300px);

  background-color: #efefef;
}

.home-left p {
  margin-top: 0px;
  margin-bottom: 15px;
  padding-left: 30px;
}

.home-left p:hover {
  color: #b42f5a;
  cursor: pointer;
}

#menu-title {
  display: flex;
  align-items: center;
  gap: 10px;
}
#menu-title h3 {
  cursor: pointer;
}

.bold {
  font-weight: bold;
  color: #b42f5a;
}

.drag-over {
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
  height: 40px !important;
  background-color: #a50a3b !important;
  border-radius: 5px;
}

.home-container {
  margin-top: 40px;
  width: 100%;
}

.top-bar {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray;
}

.top-bar-nav-array {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  border: 1px solid lightgray;
}
.top-bar-nav-array div {
  cursor: pointer;
  &:hover {
    color: #b42f5a;
    text-decoration: underline;
  }
}

.contact-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 255px);
  width: 100%;
  justify-content: space-between;
  gap: 50px 30px;
  flex-wrap: wrap;
  margin: auto, 40px;
}

.contact-grid::after {
  content: "";
  width: 255px;
}

/* Contact cards CSS */
.contact-cards {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 300px;
  width: 255px;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-bottom: 30px;
}

/* Status & Heat */
.contact-cards > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contact-cards > div:first-child > div:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 8px;
}
.contact-cards > div:first-child > div:last-child img {
  height: 17px;
}

.contact-cards > div:first-child > div:first-child {
  display: flex;
  align-items: center;
  height: 100%;
  width: 70%;
  color: white;
  padding: 8px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 50px;
  cursor: move;
}

/* Favicon, company name and Contact name */
.contact-cards > div:nth-child(2) {
  display: flex;
  gap: 15px;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.contact-cards > div:nth-child(2) h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 18px;
}

.contact-cards > div:nth-child(2) p {
  margin-top: 8px;
}

.contact-cards > div:nth-child(2) img {
  margin-top: 10px;
  width: 3Opx;
  height: 30px;
}

/* City and company size */
.contact-cards > div:nth-child(3) {
  padding-left: 10px;
  line-height: 25px;
  color: gray;
}
.contact-cards > div:nth-child(3) h3 {
  font-size: 14px;
  font-weight: bold;
  color: #b42f5a;
  margin-top: 8px;
  line-height: 20px;
  margin-bottom: 0px;
}

/* Owner & Folder */
.contact-cards > div:nth-child(4) {
  padding: 10px;
  line-height: 20px;
  color: gray;
}

.contact-cards > div:nth-child(4) img {
  width: 10px;
  height: 10px;
}

.contact-cards > div:nth-child(4) span {
  font-size: 10px;
  padding-left: 7px;
}

/* Phone number */
.contact-cards > div:nth-child(5) {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  padding: 10px;
  background-color: #efefef;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.contact-cards > div:nth-child(5) img {
  width: 15px;
  height: 15px;
}

/* Quick Actions */
.contact-cards-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 0px 0px 5px 5px;
  bottom: 0px;
  left: 0px;
  width: 254px;
  height: 190px;
  background-color: rgba(180, 47, 90, 0.9);
  color: white;
}

.contact-cards-actions div {
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.contact-cards-actions img {
  width: 35px;
  height: 35px;
}

/* Contact cards Dragging */
.contact-card-dragging {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
  height: 50px;
  width: 255px;
  background-color: rgb(44, 44, 44);
  color: white;
  border-radius: 5px;
  font-weight: bold;
  cursor: move;
  opacity: 0.2;
}

.contact-card-transparent {
  opacity: 0.2;
}

/* New Contact first grid card */
.new-contact-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 300px;
  width: 255px;
  background-color: #b42f5a;
  border: 1px solid #b42f5a;
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 5px;
  cursor: pointer;
}

.new-contact-card:hover {
  background-color: #a50a3b;
}

.new-contact-card > h1 {
  color: white;
}

.new-contact-card img {
  width: 50px;
  height: 50px;
  margin-left: 15px;
}

.top-bar input {
  border: lightgrey 1px solid;
  height: 30px;
  width: 250px;
  padding: 15px;
}
.top-bar select {
  border: lightgrey 1px solid;
  height: 30px;
  width: 250px;
  margin-right: 5px;
}
.create-button {
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  height: 30px;
  background-color: #b42f5a;
  color: white;
}

/* FILTERS */

.filters-hide {
  display: none;
}

/* CREATE CONTACT */

.display-buttons {
  display: flex;
  gap: 20px;
  align-items: center;
  color: gray;
}

.display-buttons > div {
  cursor: pointer;
}

/* CREATE V2 */

.create-contact-container {
  display: flex;
  margin-top: 25px;
  flex-direction: column;
  align-items: center;
  width: 70%;
  min-height: calc(100vh - 200px);
  margin-left: auto;
  margin-right: auto;
}

.create-contact-container div {
  width: 100%;
}

.create-contact-container div h1 {
  margin-left: 0px;
}

.create-contact-container div h3 {
  margin-left: 0px;
  font-weight: bold;
}

.create-contact-container div p {
  margin-left: 0px;
  color: grey;
  line-height: 30px;
}

.link {
  color: #a50a3b;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: #b42f5a;
  }
}

.create-contact-container div a {
  margin-left: 0px;
  font-size: 12px;
  color: #a50a3b;
  line-height: 30px;
}

.create-contact-container div a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.create-contact-company-resumee {
  border-bottom: 1px lightgray solid;
  margin-bottom: 15px;
  padding-bottom: 20px;
}

.create-contact-container input {
  width: 100%;
  height: 45px;
  border-radius: 8px;
  border: 1px lightgray solid;
  font-size: 15px;
  padding-left: 10px;
  margin-bottom: 15px;
}

.create-contact-container button {
  background-color: #a50a3b;
  color: white;
  width: 100%;
  height: 45px;
  border: none;
  margin-top: 20px;
  margin-bottom: 40px;
  cursor: pointer;
}

.create-contact-container button:hover {
  background-color: #b42f5a;
}

.create-contact-container input:focus {
  outline: none;
  border: #a50a3b solid 2px;
}

.create-contact-results {
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 25px;
  padding-left: 25px;
  margin: 25px 0;
  border: 1px lightgrey solid;
}

.create-contact-results div:last-child {
  padding-top: 20px;
  padding-right: 35px;
  width: 20px;
}
.create-contact-results:hover {
  border: #a50a3b solid 2px;
  cursor: pointer;
}

.create-contact-activity {
  text-align: center;
}

.created-by {
  margin-bottom: 15px;
  font-size: 12px;
  color: grey;
}

.edition-name-option {
  display: flex;
  gap: 30px;
  align-items: center;
}

.edition-name-option-input {
  font-size: 20px;
  border: solid 1px lightcoral;
  border-style: dotted;
  padding: 15px;
}

.edition-contact-option {
  font-size: 15px;
  border: solid 1px lightcoral;
  border-style: dotted;
  padding: 15px;
}

.edition-contact-description {
  font-size: 12px;
  width: 585px;
  height: 80px;
  border: solid 1px green;
  border-style: dotted;
  padding: 15px;
}

.update-button {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  height: 30px;
  /* width: 250px; */

  color: white;
  cursor: pointer;
}
.normal-button {
  background-color: #b42f5a;
}
.red-button {
  background-color: red;
}

.options-bar {
  display: flex;
  gap: 30px;
}

.favicon {
  width: 100px;
  height: 100px;
}
